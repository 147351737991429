<template>
  <section class='section-fv' id="section-fv">
    <div class='section-fv__video'>
      <div class='section-fv__video__overlay'></div>
      <img
        :src='setImageUrl("images/other/fv_image.png")'
        id="section-fv__video__loading__img"
        alt="loading_img"
      >
      <video
        autoplay
        loop
        muted
        playsinline
        class="section-fv__video__display__none"
        id="section-fv__video__main"
      >
        <source :src='setImageUrl("images/other/fv.webm")' type='video/webm' />
        <source :src='setImageUrl("images/other/fv.mp4")' type='video/mp4' />
      </video>
    </div>
    <div class='section-fv__content'>
      <p class='section-fv__content__sub-title'>サブスク型オンラインプログラミングスクール</p>
      <div class='section-fv__content__title'>
        <h1>サブスクで目指す</h1>
        <h1>未経験からWebエンジニア</h1>
      </div>
      <ul class='section-fv__content__feature'>
        <li v-for='feature in fvFeature' :key='feature.id' v-html="feature"></li>
      </ul>
      <cv-button @changeShowModal='$emit("changeShowModal", "signup", true)'/>
    </div>
  </section>
</template>
<script>
import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    CvButton,
  },
  data() {
    return {
      fvFeature: [
        '業界<span>最安級</span>',
        '充実の<span>学習コンテンツ</span>',
        '<span>チャットコミュニティ</span>',
      ],
    };
  },
  mounted() {
    const video = document.getElementById('section-fv__video__main');
    video.addEventListener('canplay', () => {
      video.classList.remove('section-fv__video__display__none');
    });
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
  },
};
</script>
<style lang="scss">
.section-fv {
  height: 900px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
}

.section-fv__video {
  position: absolute;
  top: 0;
  z-index: -100;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  #section-fv__video__main {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__display__none {
    display: none;
  }
  #section-fv__video__loading__img {
    object-fit: cover;
    min-width: 1280px;
    min-height: 900px;
    height: 100%;
    width: 100%;
  }
}

.section-fv__video__overlay {
  position: absolute;
  z-index: 1;
  background-color: rgba(173, 173, 173, 0.2);
  width: 100%;
  height: 100%
}

.section-fv__content {
  margin: 0 auto;
  width: 1170px;
  padding: 0 30px;
}

.section-fv__content__sub-title {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

.section-fv__content__title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  h1 {
    font-size: 56px;
    margin: 3px 0;
    padding: 5px 20px;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.section-fv__content__feature {
  margin: 0;
  margin-bottom: 80px;
  display: flex;
  gap: 5px;
  li {
    background-color: #f7be42eb;
    padding: 5px 15px;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 500;
    span {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1040px) {
.section-fv__content__title h1 {
  font-size: 48px;
}

.section-fv__content__sub-title {
  font-size: 24px;
}
}

@media screen and (max-width: 650px) {
.section-fv {
  height: 600px;
  justify-content: center;
}
.section-fv__video {
  #section-fv__video__loading__img {
    min-width: 1000px;
    min-height: initial;
  }
}

.section-fv__content {
  text-align: center;
  padding: 0 15px;
}

.section-fv__content__sub-title {
  font-size: 16px;
}

.section-fv__content__title {
  align-items: center;
  h1 {
    font-size: 24px;
  }
}

.section-fv__content__feature {
  margin: 0;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  gap: 0px;
  li {
    background-color: #f7be42eb;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    margin-right: 5px;
  }
  li:nth-child(3) {
    margin-right: 0;
  }
}
}
</style>
