<template>
  <section class='section-course-lp' id='course'>
    <div class='section-course-lp__content pc-width'>
      <section-title subheading="COURSE" title="コース一覧" />
      <h5 class="section-course-lp__content__title__detail">
        目指すキャリアに合わせて選べる2コース、どちらも料金は変わりません
      </h5>
      <div class="section-course-lp__content__card__list"
        v-for="courseInfo in setCourseInfoFilterDevice"
        :key="courseInfo.index"
      >
        <div class="section-course-lp__content__card" :id="courseInfo.overview.hardName">
          <div class="section-course-lp__content__card__header">
            <img :src="setImageUrl(courseInfo.overview.img)" width="180px"/>
            <div class="section-course-lp__content__card__header__content">
              <h3 :style="{ color: courseInfo.overview.themeColor }" >
                {{ courseInfo.overview.name }}
              </h3>
              <p>{{ courseInfo.overview.description }}</p>
            </div>
            <!-- <div v-if="!courseInfo.overview.free"
               class="section-course-lp__content__card__deals">
              <p>
                <span>{{ setComma(courseInfo.overview.deals) }}円分</span><br />
                の質問チケット<br />
                を毎月配布！
              </p>
            </div> -->
          </div>
          <div
            class="section-course-lp__content__card__body"
            v-if="courseInfo.overview.isOpened"
          >
            <hr size="1px" color="#eee" />
            <h4>こんな方にオススメ</h4>
            <div class="section-course-lp__content__card__body__recommendation__list">
              <div class="section-course-lp__content__card__body__recommendation"
                v-for="recommendation in courseInfo.recommendationList"
                :key="recommendation.index"
              >
                <img :src="setImageUrl(recommendation.img)" width="80" height="80" />
                <p>{{ recommendation.point }}</p>
              </div>
            </div>
            <h4>コース内容</h4>
            <div
              class="section-course-lp__content__card__body__course__content"
              v-for="(content, idx) in courseInfo.contentList"
              :key="content.index"
            >
              <h5>{{ `${idx + 1}. ${content.title}` }}</h5>
              <p>{{ content.description }}</p>
              <div
                class="section-course-lp__content__card__body__course__content__slide__wrapper"
              >
                <hooper
                  class="section-course-lp__content__card__body__course__content__slide"
                  :settings="hooperSettings"
                >
                  <slide v-for="img in content.imgList" :key="img" >
                    <img :src="setImageUrl(img)" width="700" />
                  </slide>
                <hooper-pagination slot="hooper-addons" />
                <hooper-navigation slot="hooper-addons" />
                </hooper>
              </div>
            </div>
          </div>
          <!-- <button
            class="section-course-lp__content__card__close"
            :style="{ 'background-color': courseInfo.overview.themeColor }"
            @click="cardToggle(courseInfo.overview)"
          > #257 -->
          <button
            class="section-course-lp__content__card__close"
            @click="cardToggle(courseInfo.overview)"
          >
            {{
              courseInfo.overview.isOpened
              ? "▲ 説明を閉じる ▲"
              : "▼ 詳しい説明を開く ▼"
            }}
          </button>
        </div>
      </div>
      <p class="section-course-lp__content__warn" />
      <!-- <p class="section-course-lp__content__warn">
        ※フロントエンドコース・バックエンドコースを並行受講できるフルスタックコースもございます。</p> -->
      <cv-button @changeShowModal='$emit("changeShowModal", "signup", true)'/>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from 'hooper';
import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    SectionTitle,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    CvButton,
  },
  data() {
    return {
      courseInfoPc: [
        // {
        //   overview: {
        //     name: 'フリーコース',
        //     hardName: 'freeCourse',
        //     description: '無料でプログラミングの基礎を学習できるコース。\nプログラミングを始めるための準備、
        // HTML/CSSの基礎学習やパズル問題を使った演習問題に取り組みます。',
        //     img: 'images/other/toppage/webp/course_logo_free.webp',
        //     free: true,
        //     isOpened: true,
        //     themeColor: '#4bbcf8',
        //     deals: null,
        //   },
        //   recommendationList: [
        //     {
        //       img: 'images/other/toppage/webp/course_recommend_1.webp',
        //       point: '初めてプログラミングにチャレンジする方',
        //     },
        //     {
        //       img: 'images/other/toppage/webp/course_recommend_2.webp',
        //       point: 'Donblerをお試しで使ってみたい方',
        //     },
        //   ],
        //   contentList: [
        //     {
        //       title: 'プログラミングを始めるための準備',
        //       description: '開発に必要なテキストエディタ「VSCode」のインストールやブラウザ「Google Chrome」のインストールを行います。',
        //       imgList: [
        //         'images/other/toppage/webp/course_detail_1.webp',
        //         'images/other/toppage/webp/course_detail_2.webp',
        //       ],
        //     },
        //     {
        //       title: 'HTML/CSSでパズル問題に挑戦',
        //       description: 'ページの骨組みを作る「HTML」とHTMLで作ったページの骨組みを装飾する「CSS」の基礎を学び、
        // それらの知識を使ってパズル問題に取り組みます。',
        //       imgList: [
        //         'images/other/toppage/webp/course_detail_3.webp',
        //         'images/other/toppage/webp/course_detail_4.webp',
        //       ],
        //     },
        //   ],
        // },
        {
          overview: {
            name: 'フロントエンドコース',
            hardName: 'frontEndCourse',
            description: 'フロントエンドエンジニアになるために必要なスキルを学習するコース。JavaScriptのフレームワーク「Vue.js」を使ったWebアプリの完成を目指します。',
            img: 'images/other/toppage/webp/course_logo_front.webp',
            free: false,
            isOpened: true,
            themeColor: '#FF984E',
            deals: 3080,
          },
          recommendationList: [
            {
              img: 'images/other/toppage/webp/course_recommend_3.webp',
              point: 'フロントエンドエンジニア\nになりたい方',
            },
            {
              img: 'images/other/toppage/webp/course_recommend_4.webp',
              point: 'ユーザーに見える部分\n(Webページの見た目など)の開発に関わりたい方',
            },
          ],
          contentList: [
            {
              title: 'HTML/CSSの文法練習・レスポンシブサイトの制作',
              description: 'HTML/CSSの基礎を学習した後、Webページよく使用されるレイアウトの作成やレスポンシブサイトの制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_5.webp',
                'images/other/toppage/webp/course_detail_6.webp',
                'images/other/toppage/webp/course_detail_7.webp',
              ],
            },
            {
              title: 'JavaScript/jQueryを用いた機能制作',
              description: 'JavaScriptの文法を学習した後、JavaScriptのライブラリ「jQuery」を使ってWebページ制作で活用できる機能の制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_8.webp',
                'images/other/toppage/webp/course_detail_9.webp',
                'images/other/toppage/webp/course_detail_10.webp',
              ],
            },
            {
              title: 'Vue.jsでのWebアプリ開発',
              description: 'JavaScriptのフレームワーク「Vue.js」を学びます。まず文法を学習し、その後TODOアプリの制作に取り組みます。',
              imgList: [
                'images/other/toppage/webp/course_detail_11.webp',
                'images/other/toppage/webp/course_detail_12.webp',
              ],
            },
          ],
        },
        {
          overview: {
            name: 'バックエンドコース',
            hardName: 'backEndCourse',
            description: 'バックエンドエンジニアになるために必要なスキルを学習するコース。PHPのフレームワーク「Laravel」を使ったWebアプリの完成を目指します。',
            img: 'images/other/toppage/webp/course_logo_back.webp',
            free: false,
            isOpened: false,
            themeColor: '#9462FF',
            deals: 3080,
          },
          recommendationList: [
            {
              img: 'images/other/toppage/webp/course_recommend_5.webp',
              point: 'バックエンドエンジニア\nになりたい方',
            },
            {
              img: 'images/other/toppage/webp/course_recommend_6.webp',
              point: 'ユーザーに見えない部分\n(ユーザーの入力したデータの処理など)の開発に関わりたい方',
            },
          ],
          contentList: [
            {
              title: 'HTML/CSSの文法練習・レスポンシブサイトの制作',
              description: 'HTML/CSSの基礎を学習した後、Webページよく使用されるレイアウトの作成やレスポンシブサイトの制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_5.webp',
                'images/other/toppage/webp/course_detail_6.webp',
                'images/other/toppage/webp/course_detail_7.webp',
              ],
            },
            {
              title: 'JavaScript/jQueryを用いた機能制作',
              description: 'JavaScriptの文法を学習した後、JavaScriptのライブラリ「jQuery」を使ってWebページ制作で活用できる機能の制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_8.webp',
                'images/other/toppage/webp/course_detail_9.webp',
              ],
            },
            {
              title: 'LaravelでのWebアプリ開発',
              description: 'PHPの文法を学んだ後、フレームワーク「Laravel」を用いてTODOアプリを実装します。合わせてLinux、Dockerなど作成したアプリケーションをWeb上に公開するために必要な知識を学びます。',
              imgList: [
                'images/other/toppage/webp/course_detail_13.webp',
                'images/other/toppage/webp/course_detail_14.webp',
                'images/other/toppage/webp/course_detail_15.webp',
              ],
            },
          ],
        },
      ],
      courseInfoSp: [
        // {
        //   overview: {
        //     name: 'フリーコース',
        //     hardName: 'freeCourse',
        //     description: '無料でプログラミングの基礎を学習できるコース。\nプログラミングを始めるための準備、
        // HTML/CSSの基礎学習やパズル問題を使った演習問題に取り組みます。',
        //     img: 'images/other/toppage/webp/course_logo_free.webp',
        //     free: true,
        //     isOpened: true,
        //     themeColor: '#4bbcf8',
        //     deals: null,
        //   },
        //   recommendationList: [
        //     {
        //       img: 'images/other/toppage/webp/course_recommend_1.webp',
        //       point: '初めてプログラミングにチャレンジする方',
        //     },
        //     {
        //       img: 'images/other/toppage/webp/course_recommend_2.webp',
        //       point: 'Donblerをお試しで使ってみたい方',
        //     },
        //   ],
        //   contentList: [
        //     {
        //       title: 'プログラミングを始めるための準備',
        //       description: '開発に必要なテキストエディタ「VSCode」、ブラウザ「Google Chrome」のインストールを行います。',
        //       imgList: [
        //         'images/other/toppage/webp/course_detail_sp_1.webp',
        //         'images/other/toppage/webp/course_detail_sp_2.webp',
        //       ],
        //     },
        //     {
        //       title: 'HTML/CSSでパズル問題に挑戦',
        //       description: 'HTML/CSSの基礎を学び、それらの知識を使ってパズル問題に取り組みます。',
        //       imgList: [
        //         'images/other/toppage/webp/course_detail_sp_3.webp',
        //         'images/other/toppage/webp/course_detail_sp_4.webp',
        //       ],
        //     },
        //   ],
        // },
        {
          overview: {
            name: 'フロントエンドコース',
            hardName: 'frontEndCourse',
            description: 'フロントエンドエンジニアになるために必要なスキルを学習するコース。「Vue.js」を使ったWebアプリの完成を目指します。',
            img: 'images/other/toppage/webp/course_logo_front.webp',
            free: false,
            isOpened: true,
            themeColor: '#FF984E',
            deals: 3080,
          },
          recommendationList: [
            {
              img: 'images/other/toppage/webp/course_recommend_3.webp',
              point: 'フロントエンドエンジニア\nになりたい方',
            },
            {
              img: 'images/other/toppage/webp/course_recommend_4.webp',
              point: 'ユーザーに見える部分\n(Webページの見た目など)の開発に関わりたい方',
            },
          ],
          contentList: [
            {
              title: 'HTML/CSS基礎・レスポンシブサイト制作',
              description: 'HTML/CSS基礎を学び、Webページでよく使用するレイアウトの作成・レスポンシブサイトの制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_5.webp',
                'images/other/toppage/webp/course_detail_sp_6.webp',
                'images/other/toppage/webp/course_detail_sp_7.webp',
              ],
            },
            {
              title: 'JavaScript/jQueryを用いた機能制作',
              description: 'JavaScriptの文法学習とライブラリ「jQuery」の使い方を学びます。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_8.webp',
                'images/other/toppage/webp/course_detail_sp_9.webp',
                'images/other/toppage/webp/course_detail_sp_10.webp',
              ],
            },
            {
              title: 'Vue.jsでのWebアプリ開発',
              description: 'フレームワーク「Vue.js」を学びます。文法学習後、TODOアプリの制作に取り組みます。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_12.webp',
                'images/other/toppage/webp/course_detail_sp_11.webp',
              ],
            },
          ],
        },
        {
          overview: {
            name: 'バックエンドコース',
            hardName: 'backEndCourse',
            description: 'バックエンドエンジニアになるために必要なスキルを学習するコース。「Laravel」を使ったWebアプリの完成を目指します。',
            img: 'images/other/toppage/webp/course_logo_back.webp',
            free: false,
            isOpened: false,
            themeColor: '#9462FF',
            deals: 3080,
          },
          recommendationList: [
            {
              img: 'images/other/toppage/webp/course_recommend_5.webp',
              point: 'バックエンドエンジニア\nになりたい方',
            },
            {
              img: 'images/other/toppage/webp/course_recommend_6.webp',
              point: 'ユーザーに見えない部分\n(ユーザーの入力したデータの処理など)の開発に関わりたい方',
            },
          ],
          contentList: [
            {
              title: 'HTML/CSS基礎・レスポンシブサイト制作',
              description: 'HTML/CSS基礎を学び、Webページでよく使用するレイアウトの作成・レスポンシブサイトの制作を行います。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_5.webp',
                'images/other/toppage/webp/course_detail_sp_6.webp',
                'images/other/toppage/webp/course_detail_sp_7.webp',
              ],
            },
            {
              title: 'JavaScript/jQueryを用いた機能制作',
              description: 'JavaScriptの文法学習とライブラリ「jQuery」の使い方を学びます。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_8.webp',
                'images/other/toppage/webp/course_detail_sp_9.webp',
              ],
            },
            {
              title: 'LaravelでのWebアプリ開発',
              description: 'PHPの文法を学んだ後、フレームワーク「Laravel」を用いてTODOアプリを実装します。',
              imgList: [
                'images/other/toppage/webp/course_detail_sp_13.webp',
                'images/other/toppage/webp/course_detail_sp_14.webp',
                'images/other/toppage/webp/course_detail_sp_15.webp',
              ],
            },
          ],
        },
      ],
      hooperSettings: {
        itemsToShow: 1,
        wheelControl: false,
        infiniteScroll: true,
      },
      currentSlide: 0,
    };
  },
  computed: {
    setCourseInfoFilterDevice() {
      const isSp = (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
      return isSp ? this.courseInfoSp : this.courseInfoPc;
    },
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    setComma(price) {
      return Math.round(price).toLocaleString();
    },
    cardToggle(obj) {
      const overview = obj;
      overview.isOpened = !overview.isOpened;
      if (!overview.isOpened) {
        this.closeToAutoScroll(overview.hardName);
      }
    },
    closeToAutoScroll(hardName) {
      const margin = 28;
      const targetPosition = document.getElementById(hardName).offsetTop;
      const headerHeight = document.getElementById('header').clientHeight;
      const position = targetPosition - headerHeight - margin;
      window.scrollTo({
        top: position,
      });
    },
  },
};
</script>
<style lang="scss">
.section-course-lp {
  // background-color: #f2f7fc;
  background-color: #eff5f5;
  padding: 60px;
}
.section-course-lp__content {
  margin: 0 auto;
  text-align: center;
}

.section-course-lp__content__title__detail {
  text-align: center;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}
.section-course-lp__content__card {
  text-align: left;
  margin-bottom: 30px;
  position: relative;
  white-space: pre-line;
}
.section-course-lp__content__card__list {
  background-color: #fff;
}
.section-course-lp__content__card__header {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  &__content {
    margin-left: 10px;
    width: 100%;
    h3 {
      font-size: 24px;
      margin-bottom: 2px;
    }
    p {
      padding-right: 40px;
      font-weight: 500;
    }
  }
}
.section-course-lp__content__card__body {
  hr {
    margin-bottom: 20px;
  }
  padding: 0 25px;
  h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px
  }
  &__recommendation__list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 50px;
  }
  &__recommendation {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      margin-right: 15px;
      flex-shrink: 0;
    }
    p {
      padding-right: 20px;
      font-weight: 500;
    }
  }
  &__course__content {
    margin-bottom: 40px;
    h5 {
      margin-bottom: 5px;
      font-size: 20px;
    }
    p {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 14px;
    }
    &__slide__wrapper {
        position: relative;
    }
  }
}
.section-course-lp__content__card__close {
  width: 100%;
  color: $color-dark-gray; // #257
  // color: #fff; #257
  background-color: $color-gray; // #257
  padding: 5px 0;
  font-size: 16px;
}
.section-course-lp__content__card__deals {
  background-color: #FD5E59;
  position: absolute;
  transform: translate(50%, -25%) rotate(20deg);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  p {
    text-align: center;
    font-size: 11px;
  }
  span {
    font-weight: bold;
    font-size: 16px;
  }
}
// スライダーのcss
.section-course-lp__content__card__body__course__content__slide {
  width: 100%;
  margin: 0 auto;
  .hooper-list {
    overflow: hidden;
  }
  .hooper-track {
    display: inline-flex;
    align-items: center;
    margin: 0;
    li {
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
  // 不要なものを非表示
  .hooper-sr-only {
    display: none;
  }
  // ドットナビゲーション
  .hooper-indicators {
    display: flex;
    justify-content: center;
    margin: 0;
    li {
      width: 30px;
      height: 32px;
      text-align: center;
      button {
        padding: 10px;
        // 非アクティブドット
        &:before {
          content: "";
          display: block;
          height: 10px;
          width: 10px;
          background-color: #fff;
          border: 1px solid $color-dark-gray;
          top: 0;
          left: 0;
          border-radius: 50%;
        }
      }
      .is-active {
        // アクティブドット
        &:before {
          content: "";
          display: block;
          height: 10px;
          width: 10px;
          background-color: $color-dark-gray;
          top: 0;
          left: 0;
          border-radius: 50%;
        }
      }
    }
  }
  // 次へ(>)と戻る(<)ボタン
  .hooper-prev,
  .hooper-next {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%) scale(3, 5);
    opacity: 0.5;
    .icon {
      width: 18px;
    }
  }
  .hooper-next {
    left: initial;
    right: 10px;
    margin-top: 0px;
  }
}
.section-course-lp__content__warn {
  margin: 40px 0 80px 0;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 1040px) {
.section-course-lp__content__card__header {
  &__content {s
    p {
      font-size: 14px;
    }
  }
}
.section-course-lp__content__card__body {
  &__recommendation__list {
    display: block;
  }
  &__recommendation {
    width: 100%;
  }
}
}

@media screen and (max-width: 650px) {
.section-course-lp {
  padding: 30px 15px;
}
.section-course-lp__content__card__header {
  display: block;
  padding: 0 15px;
  img {
    display: flex;
    margin: 0 auto;
  }
  &__content {
    text-align: center;
    margin-left: 0;
    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    p {
      padding: 0;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
.section-course-lp__content__card__body {
  padding: 0 10px;
  text-align: center;
  h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  &__recommendation__list {
    margin-bottom: 60px;
  }
  &__recommendation {
    width: 80%;
    margin: 20px auto;
    p {
      padding-right: 0;
      font-size: 14px;
      text-align: left;
    }
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
  hr {
    margin-bottom: 30px;
  }
}
.section-course-lp__content__card__body__course__content {
  margin-bottom: 40px;
  h5 {
    font-size: 18px;
  }
  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
}
.section-course-lp__content__card__deals {
  position: static;
  background: inherit;
  color: #FD5E59;
  transform: translate(0);
  width: 100%;
  border-radius: initial;
  height: initial;
  margin-bottom: 30px;
  text-decoration: underline;
  p {
    font-size: 16px;
    font-weight: 500;
  }
  span {
    font-size: inherit;
    font-weight: 500;
  }
  br {
    display: none;
  }
}
.section-course-lp__content__card__close {
  font-size: 14px;
  padding: 10px 0;
}
.section-course-lp__content__warn {
  margin: 20px 0 40px 0;
}
.section-course-lp__content__card__body__course__content__slide {
  .hooper-prev,
  .hooper-next {
    left: 16px;
    padding: 0;
    opacity: 0.5;
  }
  .hooper-next {
    left: initial;
    right: 15px;
  }
}
}
</style>
