<template>
  <section class='section-reason' id="reason">
    <!-- <section-title subheading="REASON" title="Donblerが選ばれる理由" />
    <div class='section-reason__content pc-width'>
      <div class='section-reason__content__cards'>
        <div
          v-for='reason in reasons'
          class='section-reason__content__card'
          :key='reason.id'
          @click="changeReasonId(reason.id)"
        >
          <figure
            :class="nowReasonId === reason.id ? 'section-reason__content__card__active' : '' "
          >
            <img :src='setImageUrl(reason.selectImg)' width="100" height="100" />
          </figure>
          <div class='section-reason__content__card__num'>{{ reason.id }}</div>
          <p v-html="reason.catchCopy"></p>
        </div>
      </div>
      <div class="section-reason__content__detail">
        <img :src="setImageUrl(reasonDetail.mainImg)" width="1000" height=""/>
        <h3 v-html="reasonDetail.catchCopy"></h3>
        <p>{{ reasonDetail.description }}</p>
      </div>
    </div>
    <cv-button
      class="section-reason__cv__button"
      @changeShowModal='$emit("changeShowModal", "signup", true)'
    />
  </section> -->
    <section-title subheading="ABOUT" title="Donblerとは" />
    <div class='section-reason__desc'>
      <p>チャット質問・教材・キャリアサポートコンテンツを
        <span class='section-reason__desc__price'>
          月額8,800円
          <span class='section-reason__desc__price__tax'>
            (税込 9,680円)
          </span>
        </span>で受けられるサービスです。
      </p>
    </div>
    <p class='section-reason__enterprise'>
      ※別途見積もりにて法人研修も承っております。詳細はお問い合わせください。
    </p>
    <ul>
      <li
        class='section-reason__content pc-width'
        v-for='(reason, index) in reasons'
        :key='index'
      >
        <div class="section-reason__content__detail">
          <img :src="setImageUrl(reason.mainImg)" width="1000" height=""/>
          <h3 v-html="reason.catchCopy"></h3>
          <p>{{ reason.description }}</p>
        </div>
      </li>
    </ul>
    <!-- <cv-button
      class="section-reason__cv__button"
      @changeShowModal='$emit("changeShowModal", "signup", true)'
    /> -->
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';
// import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    SectionTitle,
    // CvButton,
  },
  data() {
    return {
      reasons: [
        // {
        //   id: 1,
        //   catchCopy: '圧倒的お得な<br>価格設定',
        //   selectImg: 'images/other/toppage/webp/reason_icon_1.webp',
        //   mainImg: 'images/other/toppage/webp/reason_detail_1.webp',
        //   description: 'Webエンジニアになりたい想いはあるのに、
        // まとまったお金が用意できずプログラミングスクールに通えない方でもチャレンジいただけるよう、できるだけ安い価格で高い品質の学習教材を提供します。',
        // },
        // {
        //   id: 2,
        //   catchCopy: '無料教材<br>も充実',
        //   selectImg: 'images/other/toppage/webp/reason_icon_2.webp',
        //   mainImg: 'images/other/toppage/webp/reason_detail_2.webp',
        //   description: '序盤の基礎部分は無料で受講できます。
        // Donblerが自分にあっているか試してみたい場合はもちろん、プログラミング基礎のみ学習したい方もぜひご利用下さい。',
        // },
        // {
        //   id: 3,
        //   catchCopy: 'ビデオ通話<br>で不安解決',
        //   selectImg: 'images/other/toppage/webp/reason_icon_3.webp',
        //   mainImg: 'images/other/toppage/webp/reason_detail_3.webp',
        //   description: '現役エンジニアのメンターにビデオチャットを使ってわからない箇所の質問ができます。質問はチケット制、必要な時・必要なだけ質問することが可能です。',
        // },
        {
          id: 1,
          catchCopy: '130を超える教材・キャリアサポートコンテンツ',
          mainImg: 'images/other/toppage/webp/reason_detail_2.webp',
          description: 'Webエンジニアになるために必要な学習コンテンツに加えて、「エンジニアの種類」「転職の進め方」などキャリアを考える上で必要なスキルも学習できます。',
        },
        {
          id: 2,
          catchCopy: '圧倒的お得な価格設定',
          mainImg: 'images/other/toppage/webp/reason_detail_1.webp',
          description: 'Webエンジニアになりたい想いはあるのに、まとまったお金が用意できずプログラミングスクールに通えない方でもチャレンジいただけるよう、できるだけ低価格で高い品質の学習教材を提供します。',
        },
        {
          id: 3,
          catchCopy: 'プログラミングを学ぶ仲間と出会えるチャットコミュニティ',
          mainImg: 'images/other/toppage/webp/reason_detail_5.webp',
          description: 'Donbler公式のチャットコミュニティでプログラミングを学ぶ仲間と交流することができます。プログラミングの質問はもちろん、気になる記事・技術のシェアなどが行われています。',
        },
      ],
      nowReasonId: null,
      reasonDetail: {},
    };
  },
  created() {
    this.changeReasonId(1);
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    changeReasonId(id) {
      this.nowReasonId = id;
      this.reasonDetail = this.reasons.find((v) => id === v.id);
    },
  },
};
</script>
<style lang="scss">
.section-reason {
  padding: 60px;
  text-align: center;
}
.section-reason__desc {
  margin: 20px auto 20px;
  max-width: 650px;
  p {
    font-weight: 600;
    font-size: 24px;
  }
  &__price {
    // text-decoration: underline solid #f7be42;
    // text-underline-offset: 8px;
    border-bottom-style: solid;
    border-color: #f7be42;
    border-width: 4px;
    padding-bottom: 2px;
    &__tax {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.section-reason__enterprise {
  font-size: 12px;
  margin-bottom: 30px;
}

.section-reason__content {
  margin: 80px auto 0;
}

.section-reason__content:first-child {
  margin: 0 auto;
}

.section-reason__content__cards {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 40px;
}
.section-reason__content__card {
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  figure {
    margin: 0 auto 15px auto;
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
  &__num {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $color-yellow;
    height: 35px;
    width: 35px;
    font-size: 20px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: 16px;
    font-weight: 600;
  }
  &__active {
    position: relative;
    border: 6px solid $color-yellow;
    img {
     position: absolute;
     top: -6px;
     left: -6px;
    }
  }
}
.section-reason__content__detail {
  margin-bottom: 100px;
  img {
    margin-bottom: 20px;
    max-width: 1000px;
    width: 100%;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 24px;
    &> br {
      display: none;
    }
  }
  p {
    white-space: pre-line;
    font-size: 16px;
    font-weight: 500;
  }
}
.section-reason__content__detail:last-child {
  margin-bottom: 0;
}

.section-reason__cv__button {
  margin: 0 auto;
}

@media screen and (max-width: 650px) {
.section-reason {
  padding: 30px 10px;
}

.section-reason__content {
  margin-top: 30px;
}

.section-reason__desc {
  p {
    font-size: 18px;
  }
  &__price {
    border-width: 3px;
    padding-bottom: 1px;
  }
}

.section-reason__content__detail {
  margin-bottom: 80px;
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  img {
    width: 100%;
  }
}
.section-reason__content__card {
  margin: 0 10px;
  figure {
    width: 90px;
    height: 90px;
    img {
      width: 90px;
      height: 90px;
    }
  }
  &__num {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  &__active {
    position: relative;
    border: 5px solid $color-yellow;
    img {
     position: absolute;
     top: -5px;
     left: -5px;
    }
  }
}
}
</style>

<!--
<template>
  <section class='section-reason'>
    <section-title subheading="REASON" title="選ばれる3つの理由" />
    <div class='section-reason__content pc-width'>
      <div class='section-reason__content__first'>
        <div class='section-reason__content__first__info'>
          <div class='section-reason__content__first__info__top'>
            <img :src='setImageUrl("images/other/toppage/webp/reason_number01.webp")'>
            <h5>エンジニアになるための</h5>
            <h3><span>教育をワンストップで提供</span></h3>
          </div>
          <p class='section-reason__content__first__info__bottom'>
            Donblerでは<span>「現役エンジニア監修の教材」「疑問点をすぐ聞ける質問環境」「学習ロードマップ」</span>を
            一貫してご用意！<br><br>
            修了後<span>すぐに現場で活かせるスキル</span>が身につきます。<br>
            さらに、エンジニア就職に活かせる<span>「面接のコツ」や「転職ノウハウ」</span>
            コンテンツも随時更新しています。
          </p>
        </div>
        <div class='section-reason__content__first__img'>
          <img :src='setImageUrl("images/other/toppage/webp/reason_human01.webp")'>
          <div />
        </div>
      </div>
      <div class='section-reason__content__second'>
        <div class='section-reason__content__second__img'>
          <img :src='setImageUrl("images/other/toppage/webp/reason_human02.webp")'>
          <div />
        </div>
        <div class='section-reason__content__second__info'>
          <div class='section-reason__content__second__info__top'>
            <img :src='setImageUrl("images/other/toppage/webp/reason_number02.webp")'>
            <h5>未経験でも安心できる</h5>
            <h3><span>充実のサポート体制</span></h3>
          </div>
          <p class='section-reason__content__second__info__bottom'>
            プログラミング学習の難所は<span>「エラーが解決できない」</span>こと。<br>
            特に未経験スタートの場合、情報の取捨選択も一苦労…。<br><br>
            Donblerでは<span>現役エンジニアとのビデオチャット付き！</span><br>
            丁寧なサポートで<span>分からないことがすぐに・詳しく聞ける</span>ので、効率的に、かつ実践的に学習を進めることができます。<br>
          </p>
        </div>
      </div>
      <div class='section-reason__content__third'>
        <div class='section-reason__content__third__info'>
          <div class='section-reason__content__third__info__top'>
            <img :src='setImageUrl("images/other/toppage/webp/reason_number03.webp")'>
            <h5>好きなときに好きなだけ</h5>
            <h3><span>月額課金で気軽にスタート</span></h3>
          </div>
          <p class='section-reason__content__third__info__bottom'>
            Donblerは<span>月額課金制</span>のプログラミングスクール。
            だからまとまった資金を用意しなくても、気軽に学習を始められます。<br><br>
            最低3ヶ月契約必須、といった<span>契約回数のお約束もない</span>ので
            1ヶ月単位で継続をするかしないか判断OK！<br>
            <span>自分の好きなペースで学習を進められる</span>というのも
            Donblerが選ばれる理由の一つです。<br>
          </p>
        </div>
        <div class='section-reason__content__third__img'>
          <img :src='setImageUrl("images/other/toppage/webp/reason_human03.webp")'>
          <div />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';

export default {
  components: {
    SectionTitle,
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
  },
};
</script>
<style lang="scss">
.section-reason {
  padding: 60px 60px 0 60px;
}

.section-reason__content {
  margin: 60px auto;
}

.section-reason__content__first {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.section-reason__content__first__info__top {
  width: 120%;
  position: relative;
  margin-bottom: 20px;
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  h3 {
    font-size: 24px;
    span {
      border-bottom: 3px solid #f7be42;
    }
  }
  img {
    height: 76px;
  }
}

.section-reason__content__first__info__bottom {
  font-size: 14px;
  span {
    font-weight: 600;
  }
}

.section-reason__content__first__img {
  width: 600px;
  margin-left: 40px;
  position: relative;
  img {
    width: 100%;
    border-radius: 500px;
    border: 15px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    background-color: #F2F5F5;
    width: 100vh;
    height: 98.5%;
    position: absolute;
    top: 0;
    left: 160px;
    z-index: -1;
  }
}

.section-reason__content__second {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.section-reason__content__second__info__top {
  width: 120%;
  position: relative;
  margin-bottom: 20px;
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  h3 {
    font-size: 24px;
    span {
        border-bottom: 3px solid #f7be42;
    }
  }
  img {
    width: 120px;
  }
}

.section-reason__content__second__info__bottom {
  font-size: 14px;
  span {
    font-weight: 600;
  }
}

.section-reason__content__second__img {
  width: 600px;
  margin-right: 40px;
  position: relative;
  img {
    width: 100%;
    border-radius: 500px;
    border: 15px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    background-color: #F2F5F5;
    width: 100vh;
    height: 98.5%;
    position: absolute;
    top: 0;
    right: 50%;
    left: auto;
    z-index: -1;
  }
}

.section-reason__content__third {
  display: flex;
  align-items: center;
}

.section-reason__content__third__info__top {
  width: 120%;
  position: relative;
  margin-bottom: 20px;
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  h3 {
    font-size: 24px;
    span {
        border-bottom: 3px solid #f7be42;
    }
  }
  img {
    width: 120px;
  }
}

.section-reason__content__third__info__bottom {
  font-size: 14px;
  span {
    font-weight: 600;
  }
}

.section-reason__content__third__img {
  width: 600px;
  margin-left: 40px;
  position: relative;
  img {
    width: 100%;
    border-radius: 500px;
    border: 15px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    background-color: #F2F5F5;
    width: 100vh;
    height: 98.5%;
    position: absolute;
    top: 0;
    left: 160px;
    z-index: -1;
  }
}

@media screen and (max-width: 1040px) {
.section-reason__content__first__img {
  div {
    left: 50%;
  }
}

.section-reason__content__second__img {
  div {
    right: 50%;
    left: auto;
  }
}

.section-reason__content__third__img {
  div {
    left: 50%;
  }
}
}

@media screen and (max-width: 650px) {
.section-reason {
  padding: 30px 0 0;
}

.section-reason__content {
  margin: 40px auto;
}

.section-reason__content__first {
  flex-direction: column-reverse;
  margin-bottom: 60px;
}

.section-reason__content__first__info__top {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  h5 {
    font-size: 16px;
  }
  h3 {
    font-size: 20px;
    span {
      border-bottom: 3px solid #f7be42;
    }
  }
  img {
    display: none;
  }
}

.section-reason__content__first__info__bottom {
  font-size: 12px;
  padding: 0 15px;
  text-align: center;
  span {
    font-weight: 600;
  }
}

.section-reason__content__first__img {
  width: 220px;
  margin-left: 0;
  img {
    width: 100%;
    border-radius: 500px;
    border: 5px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    display: none;
  }
}

.section-reason__content__second {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.section-reason__content__second__info__top {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  h5 {
    font-size: 16px;
  }
  h3 {
    font-size: 20px;
    span {
      border-bottom: 3px solid #f7be42;
    }
  }
  img {
    display: none;
  }
}

.section-reason__content__second__info__bottom {
  font-size: 12px;
  padding: 0 15px;
  text-align: center;
  span {
    font-weight: 600;
  }
}

.section-reason__content__second__img {
  width: 220px;
  margin-left: 0;
  img {
    width: 100%;
    border-radius: 500px;
    border: 5px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    display: none;
  }
}

.section-reason__content__third {
  flex-direction: column-reverse;
}

.section-reason__content__third__info__top {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  h5 {
    font-size: 16px;
  }
  h3 {
    font-size: 20px;
    span {
      border-bottom: 3px solid #f7be42;
    }
  }
  img {
    display: none;
  }
}

.section-reason__content__third__info__bottom {
  font-size: 12px;
  padding: 0 15px;
  text-align: center;
  span {
    font-weight: 600;
  }
}

.section-reason__content__third__img {
  width: 220px;
  margin-left: 0;
  img {
    width: 100%;
    border-radius: 500px;
    border: 5px solid #F2F5F5;
    background-color: #F2F5F5;
  }
  div {
    display: none;
  }
}
}
</style>
-->
