<template>
  <section class="section-plan" id='plan'>
    <img
      class="section-plan__bg"
      :src='setImageUrl("images/other/toppage/webp/plan_bg_img.webp")'
      alt=""
      srcset=""
    >
    <div class="section-plan__content pc-width">
      <section-title subheading="PLAN" title="料金プラン" />
      <h5 class="section-plan__content__title__detail">
        Donblerの料金は毎月定額のサブスク型、必要な時好きなだけ契約できます
      </h5>
      <div class="section-plan__content__body">
        <div class="section-plan__content__body__price">
          <p>¥8,800<span>/月(税込¥9,680)</span></p>
        </div>
        <div class="section-plan__content__body__desc">
          <ul>
            <li>
              <i class='fa-solid fa-circle-check' />
              <p>1ヶ月間無料体験クーポン</p>
            </li>
            <li>
              <i class='fa-solid fa-circle-check' />
              <p>入会金・解約金一切無し</p>
            </li>
            <li>
              <i class='fa-solid fa-circle-check' />
              <p>130を超える教材・キャリアサポートコンテンツ</p>
            </li>
            <li>
              <i class='fa-solid fa-circle-check' />
              <p>質問・相談ができるチャットコミュニティ</p>
            </li>
          </ul>
        </div>
      </div>
      <p class="section-plan__content__warn">
        ※コンテンツの件数は2コース合計の件数です。<br>
        ※1ヶ月間無料体験クーポンを使用した無料期間終了後は通常料金で自動更新となります。
      </p>
      <!-- <div class="section-plan__content__course">
        <h3>コース</h3>
        <ul>
          <li class="section-plan__content__course__list">
            <div class="section-plan__content__course__list__name">
              <p>フリーコース</p>
              <p><span>お試しで受講してみたい方にオススメ</span></p>
            </div>
            <p>無料</p>
          </li>
          <li class="section-plan__content__course__list">
            <div class="section-plan__content__course__list__name">
              <p>フロントエンドコース/バックエンドコース</p>
              <p><span>エンジニアを目指し専門的な知識を身につけたい方にオススメ</span></p>
            </div>
            <p class="section-plan__content__course__list__price">
              <strong>￥8,800</strong>
              /月(税込￥9,680)
            </p>
          </li>
        </ul>
        <p>フルスタックコースは月額13,200円(税込14,520円)で受講できます。</p>
      </div>
      <div class="section-plan__content__ticket">
        <h3>チケット</h3>
        <div class="section-plan__content__ticket__tab">
          <ul class="section-plan__content__ticket__tab__header">
            <li
              v-for="ticketType in setTicketTypes"
              :key="ticketType.idx"
              @click="selectTicketType(ticketType.idx)"
              :class="{ active: showTicketType.name === ticketType.name }"
            >
              {{ ticketType.name }}
            </li>
          </ul>
          <div class="section-plan__content__ticket__tab__body">
            <p >{{ showTicketType.description }}</p>
            <ul>
              <li v-for="ticket in showTicketType.ticketList" :key="ticket.index">
                <div class="section-plan__content__ticket__tab__body__label">
                  <img :src="setImageUrl(showTicketType.img)" :alt="ticket.name">
                  <div>
                    <p>{{ ticket.name }}</p>
                    <span v-if="ticket.bundle">
                      <strong>￥{{ setComma((ticket.simplePrice - ticket.price) * 1.1) }}</strong>
                      (税込)お得！
                    </span>
                  </div>
                </div>
                <div class="section-plan__content__ticket__tab__body__price">
                  <del v-if="ticket.bundle">
                    ￥{{ setComma(ticket.simplePrice) }}
                    <span>(税込 ￥{{ setComma(ticket.simplePrice * 1.1) }})</span>
                  </del>
                  <p>
                    <span>￥{{ setComma(ticket.price) }}</span>
                    (税込 ￥{{ setComma(ticket.price * 1.1) }})
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <cv-button @changeShowModal='$emit("changeShowModal", "signup", true)'/>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';
import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    SectionTitle,
    CvButton,
  },
  data() {
    return {
      showTicketType: [],
      ticketTypeList: [
        {
          name: '質問チケット',
          description: 'チケットを1枚使用することで30分現役エンジニアのメンターに\n質問することができます。',
          img: 'images/other/toppage/webp/ticket_icon.webp',
          ticketList: [
            {
              bundle: false,
              name: '質問チケット１枚',
              price: 2800,
            },
            {
              bundle: true,
              name: '質問チケット3枚セット',
              simplePrice: 8400,
              price: 7140,
            },
            {
              bundle: true,
              name: '質問チケット6枚セット',
              simplePrice: 16800,
              price: 11760,
            },
          ],
        },
        {
          name: '指名チケット',
          description: '質問チケットと合わせて使用することで現役エンジニアのメンターを指名することができます。',
          img: 'images/other/toppage/webp/ticket_icon.webp',
          ticketList: [
            {
              bundle: false,
              name: '指名チケット1枚',
              price: 480,
            },
            {
              bundle: true,
              name: '指名チケット3枚セット',
              simplePrice: 1440,
              price: 1224,
            },
            {
              bundle: true,
              name: '指名チケット6枚セット',
              simplePrice: 2400,
              price: 2016,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.selectTicketType();
  },
  computed: {
    setTicketTypes() {
      return this.ticketTypeList.map((v, idx) => ({ name: v.name, idx }));
    },
  },
  methods: {
    setComma(price) {
      return Math.round(price).toLocaleString();
    },
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    selectTicketType(idx = 0) {
      this.showTicketType = this.ticketTypeList[idx];
    },
    changeShowModal(target, bool) {
      this.$emit('changeShowModal', target, bool);
    },
  },
};
</script>
<style lang="scss">
.section-plan {
  padding: 60px 60px;
  // background-color: #F2F5F5;
  background-color: #b7dcff2b;
  position: relative;
  z-index: 1;
}

.section-plan__bg {
  position: absolute;
  bottom: 0;
  right: 15%;
  opacity: 0.4;
  width: 500px;
  z-index: -1;
  display: none;
}

.section-plan__content {
  margin: 0 auto;
  text-align: center;
  &__title {
    &__detail {
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}

.section-plan__content__body {
  background-color: #fff;
  padding: 30px 20px;
  &__price {
    font-size: 64px;
    font-weight: 600;
    span {
      font-size: 32px;
      font-weight: 500;
    }
    text-decoration: underline solid #f7be42;
    text-underline-offset: 15px;
  }
  &__desc {
    display: flex;
    justify-content: center;
    ul {
      li {
        display: flex;
        align-items: center;
        margin-top: 5px;
        i {
          padding-top: 5px;
          font-size: 24px;
          color: #2496FF;
        }
        p {
          font-weight: 500;
          font-size: 20px;
          margin-left: 8px;
        }
      }
    }
  }
}

.section-plan__content__warn {
  font-size: 14px;
  margin: 10px 0 60px;
}

.section-plan__content__course {
  h3 {
    font-size: 28px;
  }
  ul {
    padding: 20px 40px;
    background-color: #FFF;
    :first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    font-size: 16px;
    margin-top: 20px;
    border-top: solid #F2F5F5 3px;
    &__name {
      p {
        text-align: left;
        font-weight: bold;
        margin-top: 0;
        font-size: 20px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        background: linear-gradient(transparent 90%, #f7be42 0%);
      }
    }
    &__price {
      strong {
        font-size: 28px;
      }
      text-align: right;
    }
  }
}

.section-plan__content__ticket {
  margin-top: 60px;
  margin-bottom: 80px;
  h3 {
    font-size: 28px;
  }
}

.section-plan__content__ticket__tab {
  background-color: #FFF;
  &__header {
    display: flex;
    justify-content: space-around;
    li {
      background-color: #F9F9F9;
      width: 100%;
      padding: 20px 0;
      font-size: 20px;
    }
    .active {
      background-color: #FFF;
      font-weight: bold;
    }
  }
  &__body {
    > p {
      min-height: 72px;
    }
    background-color: #FFF;
    text-align: left;
    padding: 10px 30px;
    white-space: pre-line;
    ul {
      margin-top: 10px;
      :first-child {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
      }
    }
    li {
      display: flex;
      p {
        min-height: initial;
      }
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      margin-top: 20px;
      border-top: solid #F2F5F5 3px;
    }
  }
  &__body__label {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    img {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
    span {
      font-weight: normal;
      font-size: 10px;
      border-radius: 5px;
      background-color: #f7be42;
      padding: 8px 5px 5px;
      strong {
        font-size: 14px;
      }
    }
  }
  &__body__price {
    text-align: right;
    font-size: 20px;
    del {
      font-weight: normal;
      font-size: 18px;
      text-decoration-line: none;
      background-image: linear-gradient(#000, #000);
      background-position: 0 50%;
      background-size: 100% 2px;
      background-repeat: repeat-x;
      span {
        font-weight: normal;
        font-size: 14px;
      }
    }
    p {
      font-size: 14px;
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
.section-plan__content__ticket__tab__header {
  cursor: pointer;
}

@media screen and (max-width: 650px) {
.section-plan {
  padding: 30px 15px;
}

.section-plan__content__body {
  background-color: #fff;
  padding: 30px 20px;
  &__price {
    font-size: 40px;
    span {
      font-size: 16px;
      font-weight: 500;
    }
    text-decoration: underline solid #f7be42;
    text-underline-offset: 10px;
  }
  &__desc {
    display: flex;
    justify-content: center;
    ul {
      li {
        display: flex;
        align-items: start;
        margin-top: 5px;
        i {
          padding-top: 5px;
          font-size: 20px;
          color: #2496FF;
        }
        p {
          font-weight: 500;
          font-size: 16px;
          margin-left: 8px;
          text-align: left;
        }
      }
    }
  }
}

.section-plan__content__warn {
  font-size: 12px;
  margin: 10px 0 30px;
}

.section-plan__content__course {
  h3 {
    font-size: 22px;
  }
  margin-top: 30px;
  ul {
    padding: 20px 15px;
  }
  > p {
    font-size: 10px;
  }
  &__list {
    display: block;
    > p {
      text-align: right;
      font-size: 14px;
    }
    &__name{
      margin-bottom: 20px;
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
    &__price {
      font-size: 12px;
      strong {
        font-size: 20px;
      }
    }
  }
  &__name {
    p {
      font-size: 18px;
    }
    span {
      font-size: 12px;
    }
  }
}

.section-plan__content__ticket {
  h3 {
    font-size: 22px;
  }
  margin: 30px 0 60px;
  .section-plan__content__ticket__tab {
    &__header li {
      font-size: 18px;
      li {
        display: block;
      }
    }
    &__body {
      > p {
        font-size: 12px;
        min-height: 54px;
      }
      padding: 10px 15px;
      li {
        display: block;
      }
      &__label {
        font-size: 16px;
        span {
          strong {
            font-size: 12px;
          }
          padding: 3px;
        }
      }
      &__price {
        margin-top: 5px;
        p {
          font-size: 12px;
          span {
            font-size: 16px;
          }
        }
        del {
          font-size: 12px;
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
}
}
</style>
