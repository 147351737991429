<template>
  <div
    class='modal-close-button'
    @click='changeShowModal'
  >
    <i class='fa-regular fa-circle-xmark' />
  </div>
</template>
<script>
export default {
  methods: {
    changeShowModal() {
      this.$emit('changeShowModal', '', false);
    },
  },
};
</script>
<style lang='scss'>
.modal-close-button {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 18px;
    color: $color-dark-gray;
  }
}
</style>
