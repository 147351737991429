<template>
  <div class='top'>
    <section-auth-login
      v-if='showModal.login'
      @changeShowModal="changeShowModal"
      @authProvider="authProvider"
      @createTwitterRedirecturl="createTwitterRedirecturl"
      @authEmailLogin="authEmailLogin"
      @changeInputTypePassword="changeInputTypePassword"
      :authLoadStatus="authLoadStatus"
      :inputTypePassword="inputTypePassword"
      :emailUserInfo.sync="emailUserInfo"
    />
    <section-auth-signup
      v-if='showModal.signup'
      @changeShowModal="changeShowModal"
      @authProvider="authProvider"
      @createTwitterRedirecturl="createTwitterRedirecturl"
      @authEmailVerified="authEmailVerified"
      @changeInputTypePassword="changeInputTypePassword"
      :authLoadStatus="authLoadStatus"
      :inputTypePassword="inputTypePassword"
      :emailUserInfo.sync="emailUserInfo"
    />
    <section-auth-send-mail
      v-if='showModal.sendMail'
      @changeShowModal="changeShowModal"
    />
    <modal-campaign
      v-if='showModal.campaign'
      @changeShowModal="changeShowModal"
    />
    <notifications group="top-right"/>
    <header-normal @changeShowModal="changeShowModal" />
    <section-fv @changeShowModal="changeShowModal" />
    <section-experience />
    <!-- <section-about /> -->
    <section-reason @changeShowModal="changeShowModal" />
    <section-media @changeShowModal="changeShowModal" />
    <!-- <section-challenge @changeShowModal="changeShowModal" /> -->
    <!-- <section-recommend /> -->
    <section-plan @changeShowModal="changeShowModal" />
    <section-course @changeShowModal="changeShowModal" />
    <!-- <section-form /> -->
    <!-- <section-howToQuestion /> -->
    <section-comparison />
    <section-close @changeShowModal="changeShowModal" />
    <section-faq />
    <cv-button
      class="cv__button"
      @changeShowModal='changeShowModal("signup", true)'
    />
    <footer-normal />
  </div>
</template>

<script>
import SectionAuthLogin from '@/components/lps/top/SectionAuthLogin.vue';
import SectionAuthSignup from '@/components/lps/top/SectionAuthSignup.vue';
import SectionAuthSendMail from '@/components/lps/top/SectionAuthSendMail.vue';
import HeaderNormal from '@/components/lps/top/HeaderNormal.vue';
import SectionFv from '@/components/lps/top/SectionFv.vue';
// import SectionAbout from '@/components/lps/top/SectionAbout.vue';
import SectionExperience from '@/components/lps/top/SectionExperience.vue';
import SectionReason from '@/components/lps/top/SectionReason.vue';
import SectionMedia from '@/components/lps/top/SectionMedia.vue';
// import SectionChallenge from '@/components/lps/top/SectionChallenge.vue';
// import SectionRecommend from '@/components/lps/top/SectionRecommend.vue';
import SectionCourse from '@/components/lps/top/SectionCourse.vue';
import SectionPlan from '@/components/lps/top/SectionPlan.vue';
// import SectionForm from '@/components/lps/top/SectionForm.vue';
// import SectionHowToQuestion from '@/components/lps/top/SectionHowToQuestion.vue';
import SectionComparison from '@/components/lps/top/SectionComparison.vue';
import SectionClose from '@/components/lps/top/SectionClose.vue';
import SectionFaq from '@/components/lps/top/SectionFaq.vue';
import FooterNormal from '@/components/lps/top/FooterNormal.vue';
import CvButton from '@/components/lps/top/general/CvButton.vue';
import ModalCampaign from '@/components/lps/top/ModalCampaign.vue';

export default {
  components: {
    SectionAuthLogin,
    SectionAuthSignup,
    SectionAuthSendMail,
    ModalCampaign,
    HeaderNormal,
    SectionFv,
    SectionExperience,
    // SectionAbout,
    SectionReason,
    SectionMedia,
    // SectionChallenge,
    // SectionRecommend,
    SectionCourse,
    SectionPlan,
    // SectionForm,
    // SectionHowToQuestion,
    SectionComparison,
    SectionClose,
    SectionFaq,
    FooterNormal,
    CvButton,
  },
  data() {
    return {
      twitterUrl: null,
      authLoadStatus: false,
      inputTypePassword: true,
      showModal: {
        login: false,
        signup: false,
        sendMail: false,
        campaign: false,
      },
      emailUserInfo: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
    };
  },
  async mounted() {
    const { accessToken, key, passwordChange } = this.$route.query;

    if (key && accessToken) {
      await this.authEmailRegister(key, accessToken);
    }
    if (passwordChange === 'success') {
      this.showPasswordChangeSuccess();
    }
    if (this.$route.query.oauth_token) this.authProviderTwitter();
    // campaignモーダル表示
    // if (this.$router.referrer.name === null) {
    //   setTimeout(() => this.showCampaign(), process.env.VUE_APP_CAMPAIGN_SECONDS * 1000);
    // }
  },
  methods: {
    showNoticeBar(title, type, text) {
      this.$notify({
        title,
        text,
        type,
        speed: 400,
        group: 'top-right',
      });
    },
    showPasswordChangeSuccess() {
      this.showNoticeBar(
        'パスワード変更に成功しました',
        'success',
        '新しいパスワードでログインをお願いします',
      );
    },
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    changeShowModal(target, bool) {
      Object.keys(this.showModal).forEach((v) => { this.showModal[v] = false; });
      this.showModal[target] = bool;
      this.switchOverflow(bool);
    },
    switchOverflow(bool) {
      const body = document.querySelector('body');
      if (bool) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    },
    setSignupParams() {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      Object.keys(this.$store.state.analysis.signupParams).forEach((v) => {
        this.$store.commit('setSignupParam', [params.get(v), v]);
      });
    },
    // emailによるログイン処理
    async authEmailLogin() {
      try {
        this.authLoadStatus = true;
        await this.$store.dispatch('emailExecLogin', this.emailUserInfo);
        await this.loginDonbler();
      } catch (e) {
        if (e.message.match('EMAIL_NOT_FOUND')) {
          this.errorFunc(
            'メールアドレスログイン処理に失敗しました',
            'メールアドレスまたはパスワードが違います',
          );
        } else {
          this.errorFunc(
            'メールアドレスログイン処理に失敗しました',
            e.message,
          );
        }
        this.authLoadStatus = false;
      }
    },
    // emailによる新規会員登録処理
    async authEmailVerified() {
      try {
        this.authLoadStatus = true;
        await this.$store.dispatch('emailExecVerified', this.emailUserInfo);
        this.setSignupParams();
        this.showModal.sendMail = true;
        this.showModal.signup = false;
        this.authLoadStatus = false;
      } catch (e) {
        this.errorFunc(
          '新規会員登録処理に失敗しました',
          e.message,
        );
        this.authLoadStatus = false;
      }
    },
    // emailによる新規会員登録処理
    async authEmailRegister(key, accessToken) {
      try {
        const auth = { key, accessToken };
        this.authLoadStatus = true;
        await this.$store.dispatch('emailExecRegister', auth);
        // this.showNoticeBar(
        //   '新規登録が完了しました',
        //   'success',
        //   '自動ログインしますので少々お待ちください',
        // );
        await this.loginDonbler();
      } catch (e) {
        this.errorFunc(
          '新規会員登録処理に失敗しました',
          e.message,
        );
        this.authLoadStatus = false;
      }
    },
    async loginDonbler(resultLogin = true) {
      if (resultLogin) {
        this.authLoadStatus = false;
        await this.$store.dispatch('loadProgress');
        await this.$store.dispatch('loadTicketInfo');
        // #280 初回コース選択済みの場合
        if (this.$store.state.user.first_course_select_status === true) {
          if (this.judgeSphone()) {
            this.$router.push({ name: 'SpDashboard' });
          } else {
            this.$router.push({ name: 'Dashboard' });
          }
        } else {
          this.$router.push({ name: 'Welcome' });
        }
      } else {
        throw new Error();
      }
    },
    async authProvider(provider) {
      try {
        this.authLoadStatus = true;
        const payload = await this.$auth.authenticate(provider);
        const authInfo = { provider, payload };
        this.setSignupParams();
        const resultLogin = await this.$store.dispatch('execLogin', authInfo);
        await this.loginDonbler(resultLogin);
      } catch (e) {
        this.authLoadStatus = false;
        this.errorFunc('認証に失敗しました');
      }
    },
    async createTwitterRedirecturl() {
      this.setSignupParams();
      const { data } = await this.$http.get('api/login/twitter/');
      this.twitterUrl = data.redirect_url;
    },
    async authProviderTwitter() {
      this.authLoadStatus = true;
      const authInfo = { provider: 'twitter', payload: this.$route.query };
      const resultLogin = await this.$store.dispatch('execLogin', authInfo);
      if (resultLogin) {
        await this.loginDonbler(resultLogin);
      } else {
        this.authLoadStatus = false;
        throw new Error();
      }
    },
    changeInputTypePassword() {
      this.inputTypePassword = !this.inputTypePassword;
    },
    errorFunc(title, text = 'しばらく時間を空けてから再度お試しください') {
      this.$notify({
        title,
        text,
        speed: 400,
        type: 'error',
        group: 'top-right',
      });
    },
    showCampaign() {
      if (!Object.keys(this.showModal).filter((v) => this.showModal[v] === true).length) {
        this.changeShowModal('campaign', true);
      }
    },
    judgeSphone() {
      return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
  watch: {
    twitterUrl() {
      window.location.href = this.twitterUrl;
    },
  },
};
</script>

<style lang="scss">
.top {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

/* コンテンツ幅 */
.pc-width {
  max-width: 800px;
}
.cv__button {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 650px) {
.cv__button {
  margin-bottom: 40px;
}
}
</style>
