<template>
  <div class='section__title'>
    <p>{{ subheading }}</p>
    <h2>{{ title }}</h2>
  </div>
</template>
<script>
export default {
  props: {
    subheading: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.section__title {
  text-align: center;
  p {
    color: #b6bebe;
    font-size: 20px;
  }
  h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 650px) {
.section__title {
  text-align: center;
  p {
    font-size: 14px;
  }
  h2 {
    font-size: 24px;
  }
}
}
</style>
