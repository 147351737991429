<template>
  <div class='section-auth-login'>
    <div class='section-auth-login__overlay' @click='changeShowModal("login", false)'>
      <div class='section-auth-login__modal' @click.stop>
        <div class='section-auth-login__modal__header'>
          <modal-close-button @changeShowModal='changeShowModal' />
          <h3>ログイン</h3>
        </div>
        <div class='section-auth-login__modal__form'>
          <div class='section-auth-login__modal__form__email'>
            <div>
              <input
                type='email'
                autocomplete="email"
                name="email"
                :placeholder="email.field"
                :value="emailUserInfo.email"
                @keyup="validEmail"
                @input="$emit('update:emailUserInfo',{
                  ...emailUserInfo,
                  email: $event.target.value
                })"
              />
              <div class='section-auth-signup__modal__mail__valid' >
                <p>{{ email.valid.message[0] }}</p>
              </div>
            </div>
            <div>
              <input
                :type='inputTypePassword ? "password" : "text"'
                :value="emailUserInfo.password"
                :placeholder="password.field"
                @keyup="validPassword"
                @input="$emit('update:emailUserInfo', {
                  ...emailUserInfo,
                  password: $event.target.value
                })"
              />
              <div class='section-auth-signup__modal__mail__valid' >
                <p>{{ password.valid.message[0] }}</p>
              </div>
            </div>
            <!-- <label class='section-auth-signup__modal__mail__type'>
              <p>パスワードを表示する</p>
              <input
                type="checkbox"
                :checked="!inputTypePassword"
                @click='changeInputTypePassword()'
              />
            </label> -->
            <button
              type='submit'
              @click='authEmailLogin("email")'
              :disabled='judgeEmailButtonDisabled'
            >
              <i class='fa fa-envelope' />
              <p>メールアドレスでログイン</p>
            </button>
            <router-link to="/resetpassword">
              <p>パスワードをお忘れの方はこちら</p>
            </router-link>
          </div>
          <div class='section-auth-login__modal__form__oauth'>
            <button
              class='section-auth-login__modal__form__oauth__google'
              @click='authProvider("google")'
              :disabled='authLoadStatus'
            >
              <i class='fab fa-google' />
              <p>Googleアカウントでログイン</p>
            </button>
            <button
              class='section-auth-login__modal__form__oauth__twitter'
              @click='createTwitterRedirecturl()'
              :disabled='authLoadStatus'
            >
              <i class='fab fa-twitter' />
              <p>Twitterアカウントでログイン</p>
            </button>
            <button
              class='section-auth-login__modal__form__oauth__github'
              @click='authProvider("github")'
              :disabled='authLoadStatus'
            >
              <i class='fab fa-github' />
              <p>Githubアカウントでログイン</p>
            </button>
          </div>
        </div>
        <p>いずれかのサービスのアカウントでログインしてください。<br>続行することで
          <a href='https://donbler.notion.site/8013b863842444a5a0294d46ba629115' target='_blank' rel='noopener noreferrer'>
            利用規約
          </a> ,
          <a href='https://donbler.notion.site/8a02c8d27dbb4f5aabaa5146270c7768' target='_blank' rel='noopener noreferrer'>
            プライバシーポリシー</a>
          に同意したとみなします。
        </p>
        <div class='section-auth-login__modal__announce'>
          <p>アカウントをお持ちでない場合は</p>
          <button
            @click='changeShowModal("signup", true)'
          >
            こちら
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalCloseButton from '@/components/lps/top/general/ModalCloseButton.vue';

export default {
  components: {
    ModalCloseButton,
  },
  props: {
    authLoadStatus: {
      type: Boolean,
      required: true,
    },
    inputTypePassword: {
      type: Boolean,
      required: true,
    },
    emailUserInfo: {
      name: {
        type: String,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
      password: {
        type: String,
        required: true,
      },
    },
  },
  data() {
    return {
      email: {
        field: 'メールアドレス',
        valid: {
          message: [],
          rule: {
          },
        },
      },
      password: {
        field: 'パスワード',
        valid: {
          message: [],
          rule: {
            maxLength: 20,
            minLength: 8,
          },
        },
      },
    };
  },
  computed: {
    judgeEmailButtonDisabled() {
      return this.authLoadStatus
      || this.email.valid.message.length !== 0
      || this.password.valid.message.length !== 0
      || this.emailUserInfo.email.length === 0
      || this.emailUserInfo.password.length === 0;
    },
  },
  methods: {
    changeShowModal(target, bool) {
      this.$emit('changeShowModal', target, bool);
    },
    changeInputTypePassword() {
      this.$emit('changeInputTypePassword');
    },
    authProvider(provider) {
      this.$emit('authProvider', provider);
    },
    authEmailLogin() {
      this.checkAllValid();
      if (
        this.email.valid.message.length === 0
        && this.password.valid.message.length === 0
      ) {
        this.$emit('authEmailLogin');
      }
    },
    checkAllValid() {
      this.validEmail();
      this.validPassword();
    },
    createTwitterRedirecturl() {
      this.$emit('createTwitterRedirecturl');
    },
    validEmail() {
      const target = this.emailUserInfo.email;
      const valid = [];

      valid.push(this.checkEmailFormat(this.email.field, target));

      this.email.valid.message = valid.filter((v) => v !== '');
    },
    validPassword() {
      const { rule } = this.password.valid;
      const target = this.emailUserInfo.password;
      const valid = [];

      valid.push(this.checkMaxLength(this.password.field, rule.maxLength, target.length));
      valid.push(this.checkMinLength(this.password.field, rule.minLength, target.length));
      valid.push(this.hasLargeChar(this.password.field, target));
      valid.push(this.hasSmallChar(this.password.field, target));
      valid.push(this.hasNum(this.password.field, target));

      this.password.valid.message = valid.filter((v) => v !== '');
    },
    checkMaxLength(field, maxLen, targetLen) {
      if (maxLen < targetLen) {
        return `${field}は${maxLen}文字以内で入力してください`;
      }
      return '';
    },
    checkMinLength(field, minLen, targetLen) {
      if (minLen > targetLen) {
        return `${field}は${minLen}文字以上で入力してください`;
      }
      return '';
    },
    hasLargeChar(field, target) {
      if (!target.match(/[A-Z]/)) {
        return `${field}に大文字は必須です`;
      }
      return '';
    },
    hasSmallChar(field, target) {
      if (!target.match(/[a-z]/)) {
        return `${field}に小文字は必須です`;
      }
      return '';
    },
    hasNum(field, target) {
      if (!target.match(/[0-9]/)) {
        return `${field}に数字は必須です`;
      }
      return '';
    },
    checkEmailFormat(field, target) {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]*\.[A-Za-z0-9]+$/;
      if (!target.match(reg)) {
        return `${field}はメール形式で入力する必要があります`;
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
.section-auth-login__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
  overflow-x: hidden;
  display: flex;
}

.section-auth-login__modal {
  padding: 20px;
  background-color: #fff;
  max-width: 400px;
  z-index: 999;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  &__header {
    position: relative;
    h3 {
      font-size: 20px;
    }
  }
  p {
    font-size: 12px;
    a {
      text-decoration: none;
      color: $color-blue;
    }
    a:visited {
      color: $color-blue;
    }
  }
}

.section-auth-signup__modal__mail {
  &__valid p {
    color: #f00;
    margin-top: 0;
  }
  &__type {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}

.section-auth-login__modal__form {
  &__email {
    margin: 20px 0 40px;
    div {
      margin-bottom: 15px;
      input {
        padding: 10px;
        width: 100%;
        border: none;
        outline: none;
        background-color: $color-gray;
        border-radius: 8px;
      }
    }
    button {
      padding: 10px;
      margin: 10px 0;
      font-size: 18px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      background-color: $color-yellow;
      font-weight: 600;
      p {
        margin-left: 10px;
        font-size: 14px;
      }
    }
    button:hover {
      background-color: lighten( $color-yellow, 5% );
    }
    a {
        color: $color-blue;
        text-decoration: none;
      }
    a:visitedｌ {
      color: $color-blue;
    }
  }
  &__oauth {
    button {
      padding: 10px;
      margin: 10px 0;
      font-size: 18px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.1s;
      border: 2px solid $color-gray;
      p {
        margin-left: 10px;
        font-size: 12px;
        color: $color-black;
      }
    }
    button:hover {
      border: 2px solid $color-dark-gray;
    }
    &__twitter {
      i {
        color: #2da6e7;
      }
    }
    &__github {
      i {
        color: #2f2f2f;
      }
    }
  }
}

.section-auth-login__modal__announce {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    font-size: 12px;
    padding: 1px 0 0 0;
    color: $color-blue;
  }
}

@media screen and (max-width: 650px) {
.section-auth-login__modal {
  padding: 20px;
  max-width: 350px;
  top: 50px;
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 10px;
  }
}

.section-auth-login__modal__announce {
  button {
    font-size: 10px;
  }
}

.section-auth-login__modal__form {
  &__email {
    margin: 20px 0 40px;
    div {
      margin-bottom: 15px;
      input {
        font-size: 16px;
      }
    }
    button {
      p {
        font-size: 12px;
      }
    }
  }
  &__oauth {
    button {
      p {
        font-size: 11px;
      }
    }
  }
}
}
</style>
