<template>
  <section class='section-faq' id='faq'>
    <div class='section-faq__content pc-width'>
      <section-title subheading="FAQ" title="よくある質問" />
      <ul class='section-faq__content__list'>
        <li
          class='section-faq__content__list__info'
          v-for='(faq, index) in faqList'
          :key='index'
          @click='changeFaqShow(index)'
        >
          <div class='section-faq__content__list__info__question'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/faq_q.webp")'>
              <p>{{ faq.question }}</p>
            </div>
            <div>
              <i class='fas fa-chevron-down' v-if='!faq.status' />
              <i class='fas fa-chevron-up' v-if='faq.status' />
            </div>
          </div>
          <div class='section-faq__content__list__info__answer' v-if='faq.status'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/faq_a.webp")'>
            </div>
            <p>{{ faq.answer }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      faqList: [
        {
          status: false,
          question: 'プログラミング初心者でも受講できますか？',
          answer: 'もちろん大丈夫です。初めてプログラミングに触れる方でもわかりやすいような教材を提供します。',
        },
        {
          status: false,
          question: '受講する際用意する必要があるものはなんですか？',
          answer: 'プログラミングを学ぶ際に使用するパソコンをご用意いただく必要がございます。',
        },
        {
          status: false,
          question: 'スマートフォンからの受講は可能ですか？',
          answer: '教材の受講は可能です。コースの変更やビデオチャットの実施、退会などそれ以外の機能はPCからご利用下さい。',
        },
        {
          status: false,
          question: '推奨ブラウザはありますか？',
          answer: 'Google Chrome最新版を推奨致します。Google Chrome以外のブラウザでの動作については保証致しかねますので予めご了承下さい。',
        },
        {
          status: false,
          question: '学習の形式は講義式ですか？自習式ですか？',
          answer: '自習式です。テキストのプログラミング教材を使いご都合の良い時間に学習いただきます。',
        },
        // {
        //   status: false,
        //   question: 'まずは無料で試してみたいのですが可能ですか？',
        //   answer: '可能です。サービスに登録すると、まずは無料で受講できる「フリーコース」にチャレンジいただきます。
        //   \n「フリーコース」を受講してみて、より専門性の高い内容を学んでみたい場合は有料コースをご検討いただければと思います。',
        // },
        {
          status: false,
          question: 'まずは無料で試してみたいのですが可能ですか？',
          answer: '可能です。「フロントエンドコース」「バックエンドコース」共に最初の1ヶ月は無料で学習することができます。',
        },
        {
          status: false,
          question: '働きながら受講することは可能ですか？',
          answer: '可能です。「この時間に受講する必要がある」といった制限がないため、ご自身の時間のあるタイミングで学習を進めることができます。',
        },
        // {
        //   status: false,
        //   question: 'フリーコースではメンターへの質問は可能ですか？',
        //   answer: '可能です。その際、質問チケットのご購入が必要となります。
        //   \n有料コースをご利用いただく場合、毎月1枚の質問チケットが付与されますのでそちらも併せてご検討ください。',
        // },
        // {
        //   status: false,
        //   question: '有料コースにはメンターの質問チケットはどれくらい付いてきますか？',
        //   answer: '1ヶ月ごとに一枚付与されます。',
        // },
        // {
        //   status: false,
        //   question: 'メンターへの質問が可能な時間帯はいつですか？',
        //   answer: '平日/休日の10:00 - 22:00の間受け付けております。(ご質問のラストオーダーは21: 30までとなっております) ',
        // },
        // {
        //   status: false,
        //   question: '1枚の質問チケットで何分質問できますか？またメンターを指名することはできますか？',
        //   answer: '30分質問できます。メンターを指名する場合はあわせて「指名チケット」を1枚使用します。
        //   指名チケットは質問チケットと同様にサービス内で購入することができます。',
        // },
        // {
        //   status: false,
        //   question: 'Donbler教材以外の質問はできますか？',
        //   answer: 'Donbler教材の内容のみ受け付けております。',
        // },
        {
          status: false,
          question: '転職保証などを行なっていますか？',
          answer: '転職保証・転職斡旋などは行なっておりません。\nただ転職に向けて必要な知識・役に立つノウハウは随時提供していきます。',
        },
        // {
        //   status: false,
        //   question: 'メンターに質問する際に用いる「質問チケット」、メンターを指名する際に用いる「指名チケット」の使用期限はありますか？',
        //   answer: '有料コースのご契約により毎月付与される「質問チケット」は90日、ご購入いただいた「質問チケット」及び「指名チケット」は150日が使用期限です。',
        // },
        {
          status: false,
          question: 'どのような支払い方法がありますか？',
          answer: 'クレジットカード決済に対応しております。',
        },
        {
          status: false,
          question: '入会手数料・解約手数料など発生しますか？',
          answer: 'それぞれ一切かかりません。お好きなタイミングでサービスの利用開始・退会いただけます。',
        },
      ],
    };
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    changeFaqShow(index) {
      this.faqList[index].status = !this.faqList[index].status;
    },
  },
};
</script>
<style lang="scss">
.section-faq {
  padding: 60px;
  margin-bottom: 80px;
}

.section-faq__content {
  margin: 0 auto;
  text-align: center;
}

.section-faq__content__list {
  margin: 0;
  margin-top: 30px;
}

.section-faq__content__list__info {
  padding: 30px;
  background-color: #F2F5F5;
  margin-top: 20px;
  cursor: pointer;
}

.section-faq__content__list__info:first-child {
  margin-top: 0;
}

.section-faq__content__list__info__question {
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      width: 25px;
    }
    p {
      margin: 0 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
    i {
      font-size: 24px;
    }
  }
}

.section-faq__content__list__info__answer {
  background-color: #F2F5F5;
  display: flex;
  border-top: 1px solid #555555;
  margin-top: 30px;
  padding-top: 30px;
  img {
    width: 25px;
  }
  p {
    white-space: pre-line;
    margin-left: 10px;
    font-size: 14px;
    text-align: left;
  }
}

@media screen and (max-width: 650px) {
.section-faq {
  padding: 30px 15px;
}

.section-faq__content {
  margin: 0 auto;
  text-align: center;
}

.section-faq__content__list {
  margin-top: 20px;
}

.section-faq__content__list__info {
  padding: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.section-faq__content__list__info__question {
  div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
    p {
      font-size: 14px;
    }
    i {
      font-size: 16px;
    }
  }
}

.section-faq__content__list__info__answer {
  background-color: #F2F5F5;
  display: flex;
  justify-content: left;
  border-top: 1px solid #555555;
  margin-top: 20px;
  padding-top: 20px;
  img {
    width: 20px;
  }
  p {
    margin-left: 10px;
    font-size: 12px;
    text-align: left;
  }
}
}
</style>
