<template>
  <button class='cv-button' @click='changeShowModal()'>
    <!-- <span>10秒で学習スタート</span>
    <div class='cv-button__main-text'>
      <p>無料でプログラミングを始める</p>
      <i class="fas fa-caret-right" />
    </div> -->
    <span>無料体験クーポン配布中</span>
    <div class='cv-button__main-text'>
      <!-- <p>Webで簡単！無料体験はこちら</p> -->
      <p>Webで簡単！今すぐ試してみる</p>
      <i class="fas fa-caret-right" />
    </div>
  </button>
</template>
<script>
export default {
  methods: {
    changeShowModal() {
      this.$emit('changeShowModal', 'signup', true);
    },
  },
};
</script>
<style lang="scss">

.cv-button {
  cursor: pointer;
  position: relative;
  transform:translate3d(0,0,0); /* #275検証で追加 */
  span {
    border: 3px solid #2496FF;
    background-color: #fff;
    color: #202C31;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
    transition: 0.2s;
    z-index: 1; /* #275検証で追加 */
  }
}

.cv-button:hover span {
  border: 3px solid #379eff;
}

.cv-button__main-text {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #2c99fe;   /* #275検証で追加 */
  padding: 30px 40px;
  border-radius: 8px;
  transition: 0.2s;
  -webkit-box-shadow: 0 8px 0 #1b72c4;  /* #275検証で追加 */
  box-shadow: 0 8px 0 #1b72c4;   /* #275検証で追加 */
  p {
    font-size: 24px;
    font-weight: 600;
    margin-right: 20px;
  }
  i {
    font-size: 32px;
  }
}

// .cv-button:hover .cv-button__main-text {
//   background-color: #379eff;
// }

/* #275検証で追加 */
.cv-button:hover .cv-button__main-text {
  box-shadow: none;
  transform: translateY(8px);
}

.cv-button:hover span {
  top: -10px;
  z-index: 1;
}
/* #275検証で追加 */

@media screen and (max-width: 650px) {
  .cv-button {
    cursor: pointer;
    position: relative;
    span {
      border: 3px solid #2496FF;
      background-color: #fff;
      padding: 3px 10px;
      border-radius: 10px;
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      top: -12px;
      transition: 0.2s;
    }
  }

  .cv-button__main-text {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #2496FF;
    padding: 20px 25px;
    border-radius: 8px;
    transition: 0.2s;
    -webkit-box-shadow: 0 6px 0 #1b72c4;  /* #275検証で追加 */
    box-shadow: 0 6px 0 #1b72c4;   /* #275検証で追加 */
    p {
      font-size: 16px;
      font-weight: 600;
      margin-right: 20px;
    }
    i {
      font-size: 32px;
    }
  }

  /* #275検証で追加 */
.cv-button:hover .cv-button__main-text {
  box-shadow: 0 6px 0 #1b72c4;   /* #275検証で追加 */
  transform: translateY(0);
}

.cv-button:hover span {
  top: -12px;
}
/* #275検証で追加 */
}
</style>
