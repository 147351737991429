<template>
  <div class='section-auth-send-mail'>
    <div
      class='section-auth-send-mail__overlay'
      @click='changeShowModal("sendMail", false)'
    >
      <div class='section-auth-send-mail__modal' @click.stop>
        <i class='section-auth-send-mail__modal__icon fas fa-envelope' />
        <p>本人確認メールを送信しました。<br>届いたメールに記載のURLをクリックしログインをお願いします。</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    changeShowModal(target, bool) {
      this.$emit('changeShowModal', target, bool);
    },
  },
};

</script>
<style lang="scss">
.section-auth-send-mail__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
  overflow-x: hidden;
  display: flex;
}

.section-auth-send-mail__modal {
  padding: 20px;
  background-color: #fff;
  max-width: 400px;
  z-index: 999;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
    font-weight: 500;
  }
  &__icon {
    color: $color-yellow;
    font-size: 72px;
    margin: 10px 0 20px;
  }
}

@media screen and (max-width: 650px) {
.section-auth-send-mail__modal {
  padding: 20px;
  max-width: 350px;
  p {
    font-size: 10px;
  }
}
}
</style>
