<template>
  <section class='section-media' id='media'>
    <div class='section-media__content'>
      <section-title subheading="MEDIA" title="メディア掲載" />
      <img class='pc-width' :src='mediaImageUrl' />
      <cv-button
        @changeShowModal='$emit("changeShowModal", "signup", true)'
      />
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';
import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    SectionTitle,
    CvButton,
  },
  data() {
    return {
      mediaImageUrl: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/media_list.webp`,
    };
  },
};
</script>

<style lang="scss">
.section-media {
  &__content {
    margin: 0 auto;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      margin: 40px 0 60px;
    }
  }
}

@media screen and (max-width: 650px) {
.section-media {
  &__content {
    padding: 30px 10px;
  }
}
}
</style>
