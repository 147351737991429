<template>
  <div class='modal-campaign'>
    <div
      class='modal-campaign__overlay'
      @click='changeShowModal("campaignModal", false)'
    >
      <div class='modal-campaign__modal' @click.stop='changeShowModal("signup", true)'>
        <modal-close-button
          @changeShowModal='changeShowModal'
          class='modal-campaign__modal__close'
        />
        <img :src='setImg' alt='campaign' class='clickModalCampaign' />
      </div>
    </div>
  </div>
</template>
<script>
import ModalCloseButton from '@/components/lps/top/general/ModalCloseButton.vue';

export default {
  components: {
    ModalCloseButton,
  },
  methods: {
    changeShowModal(target, bool) {
      this.$emit('changeShowModal', target, bool);
    },
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
  },
  data() {
    return {
      campaignImg: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/banner_254_3.webp`,
    };
  },
  computed: {
    setImg() {
      return this.campaignImg;
      // return this.setImageUrl(imgUrl);
    },
  },
};
</script>
<style lang="scss">
.modal-campaign__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 998;
  overflow-x: hidden;
  display: flex;
}
.modal-campaign__modal {
  max-width: 400px;
  z-index: 999;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  &__close {
    right: 10px;
    top: 10px;
  }
}
@media screen and (max-width: 650px) {
.modal-campaign__modal {
  max-width: 350px;
}
}
</style>
