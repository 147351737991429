<template>
  <section class='footer-normal'>
    <ul class='pc-width'>
      <li v-for='menu in footerMenu' :key='menu.id'>
        <a :href='menu.link' target='_blank' rel='noopener noreferrer'>{{ menu.name }}</a>
      </li>
    </ul>
    <p>
      @Donbler 2022
    </p>
  </section>
</template>
<script>
export default {
  data() {
    return {
      footerMenu: [
        {
          name: 'おしらせ',
          link: 'https://note.com/donbler/m/md6b95a87f664',
        },
        {
          name: 'ヘルプ',
          link: 'https://donbler.notion.site/6e9c3025ff544ee7bd593d3619df6b5e',
        },
        {
          name: '運営会社',
          link: 'https://company.donbler.com',
        },
        {
          name: '利用規約',
          link: 'https://donbler.notion.site/8013b863842444a5a0294d46ba629115',
        },
        {
          name: 'プライバシーポリシー',
          link: 'https://donbler.notion.site/8a02c8d27dbb4f5aabaa5146270c7768',
        },
        {
          name: 'お問い合わせ',
          link: process.env.VUE_APP_QUESTION_FORM_URL,
        },
        {
          name: '公式Twitter',
          link: 'https://twitter.com/Donbler_jp',
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.footer-normal {
  background-color: #F2F5F5;
  padding: 40px;
  ul {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    li {
      a {
        font-size: 16px;
        text-decoration: none;
        color: #000;
      }
      a:visited {
        color: #000;
      }
    }
  }
  p {
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
.footer-normal {
  padding: 20px;
  ul {
    margin: 0 auto;
    display: block;
    text-align: center;
    li {
      margin: 10px 0;
      a {
        font-size: 14px;
        text-decoration: none;
        color: #000;
      }
      a:visited {
        color: #000;
      }
    }
  }
  p {
    font-size: 10px;
    margin-top: 40px;
    text-align: center;
  }
}
}
</style>
