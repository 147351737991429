<template>
  <section class='section-close'>
    <img
      class='section-close__background-pc'
      :src='setImageUrl("images/other/toppage/webp/close_pc.webp")'
    >
    <img
      class='section-close__background-sp'
      :src='setImageUrl("images/other/toppage/webp/close_sp.webp")'
    >
    <div class='section-close__content'>
      <div class='section-close__content__title'>
        <div class='section-close__content__title__top'>
          <h3>未経験から</h3>
          <div>
            <img :src='setImageUrl("images/other/toppage/webp/close_arrow.webp")'>
          </div>
        </div>
        <h3 class='section-close__content__title__bottom'>
          即戦力エンジニアへ
        </h3>
      </div>
      <div class='section-close__content__subtitle'>
        <div>
          <p>「できる」が増えるワクワク体験</p>
        </div>
      </div>
      <p class='section-close__content__desc'>
        可能性の扉を開くのは、ほんの少しの好奇心<br>
        まずは無料でプログラミングを<br>
        試してみませんか？
      </p>
      <cv-button @changeShowModal='$emit("changeShowModal", "signup", true)'/>
    </div>
  </section>
</template>
<script>
import CvButton from '@/components/lps/top/general/CvButton.vue';

export default {
  components: {
    CvButton,
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
  },
};
</script>
<style lang="scss">
.section-close {
  width: 100%;
  height: 550px;
  position: relative;
}

.section-close__background-pc {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.section-close__background-sp {
  display: none;
}

.section-close__content__title {
  font-size: 28px;
}

.section-close__content {
  position: absolute;
  top: 60px;
  right: 20%;
  left: auto;
  transform: none;
}

.section-close__content__title__top {
  display: flex;
  img {
    width: 40px;
    margin: 0 0 5px 5px;
  }
}

.section-close__content__title__bottom {
  margin-left: 120px;
}

.section-close__content__subtitle {
  margin: 20px auto 0;
  width: 350px;
  padding: 10px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  text-align: center;
}

.section-close__content__desc {
  font-size: 16px;
  margin: 20px 0 60px 40px;
}

@media screen and (max-width: 650px) {
.section-close {
  width: 100%;
  height: 600px;
  position: relative;
}

.section-close__background-pc {
  display: none;
}

.section-close__background-sp {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.section-close__content__title {
  font-size: 18px;
}

.section-close__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-close__content__title__top {
  display: flex;
  img {
    width: 35px;
    margin: 0 0 3px 5px;
  }
}

.section-close__content__title__bottom {
  margin-left: 60px;
}

.section-close__content__subtitle {
  margin: 20px auto 0;
  width: 90%;
  padding: 10px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  text-align: center;
  font-size: 14px;
}

.section-close__content__desc {
  font-size: 14px;
  text-align: center;
  margin: 20px 0 40px 0;
}
}
</style>
