<template>
  <section class='section-comparison'>
    <div class='section-comparison__content pc-width'>
      <section-title subheading="COMPARISON" title="他社サービスとの比較" />
      <div class='section-comparison__content__table'>
        <ul class='section-comparison__content__table__list'>
          <li class='section-comparison__content__table__list__title'></li>
          <li class='section-comparison__content__table__list__title title-donbler'>
            <p>Donbler</p>
          </li>
          <li class='section-comparison__content__table__list__title'>プログラミング<br>学習サービス</li>
          <li class='section-comparison__content__table__list__title'>プログラミング<br>スクール</li>
        </ul>
        <ul>
          <li class='section-comparison__content__table__list__title'>
            価格
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_01.webp")'>
            </div>
            <p>
              <span>月額8,800円〜</span>
            </p>
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_02.webp")'>
            </div>
            月額1,000円前後
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_04.webp")'>
            </div>
            転職サポート有50万前後、無20万前後
          </li>
        </ul>
        <ul>
          <li class='section-comparison__content__table__list__title'>
            教材難易度
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_01.webp")'>
            </div>
            <p>
              <span>Webエンジニアとして通用するレベル</span>
            </p>
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_04.webp")'>
            </div>
            初心者向け
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_01.webp")'>
            </div>
            Webエンジニアとして通用するレベル
          </li>
        </ul>
        <ul>
          <li class='section-comparison__content__table__list__title'>
            質問環境
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_01.webp")'>
            </div>
            <p>
              <span>コミュニティチャット</span>
            </p>
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_04.webp")'>
            </div>
            行ってない
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_05.webp")'>
            </div>
            チャットのみ・ビデオ通話可など
          </li>
        </ul>
        <ul>
          <li class='section-comparison__content__table__list__title'>
            転職サポート
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_03.webp")'>
            </div>
            <p>
              <span>ノウハウの提供</span>
            </p>
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_04.webp")'>
            </div>
            行ってない
          </li>
          <li class='section-comparison__content__table__list__content'>
            <div>
              <img :src='setImageUrl("images/other/toppage/webp/comparison_06.webp")'>
            </div>
            スクール毎に異なる
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';

export default {
  components: {
    SectionTitle,
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
  },
};
</script>
<style lang="scss">
.section-comparison {
  padding: 60px;
}

.section-comparison__content {
  margin: 0 auto;
  text-align: center;
}

.section-comparison__content__table {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ul {
    display: flex;
    margin: 0;
    li {
      flex: 1;
      background-color: #fff;
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
    }
    li:first-child {
      max-width: 150px;
    }
    li:nth-child(3) {
      max-width: 180px;
    }
    li:nth-child(4) {
      max-width: 180px;
    }
  }
}

.section-comparison__content__table__list__title {
  font-size: 16px;
  font-weight: 500;
  p {
    border-bottom: 3px solid #f7be42;
    font-weight: 600;
    font-size: 20px;
  }
}

.section-comparison__content__table__list__content {
  font-size: 12px;
  flex-direction: column;
  p {
    font-size: 14px;
    font-weight: 600;
  }
  img {
    height: 25px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 650px) {
.section-comparison {
  padding: 30px 15px;
}

.section-comparison__content__table {
  margin-top: 20px;
  ul {
    li {
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
    }
    li:first-child {
      max-width: 30px;
    }
    li:nth-child(3) {
      max-width: 130px;
    }
    li:nth-child(4) {
      max-width: 130px;
    }
  }
}

.section-comparison__content__table__list__title {
  font-size: 12px;
  font-weight: 500;
  p {
    border-bottom: 3px solid #f7be42;
    font-weight: 600;
    font-size: 14px;
  }
}

.section-comparison__content__table__list__content {
  font-size: 10px;
  flex-direction: column;
  p {
    font-size: 12px;
    font-weight: 600;
  }
  img {
    height: 20px;
  }
}
}

</style>
