<template>
  <section class='section-experience'>
    <div class='section-experience__content pc-width'>
      <section-title subheading="EXPERIENCES" title="受講生の声" />
      <div
        class="section-experience__content__slide"
      >
        <hooper
          class="section-experience__content__slide__hooper"
          :settings="setHooperSettings"
        >
          <slide
            class="section-experience__content__slide__hooper__list"
            v-for="experience in experienceList" :key="experience.id"
          >
            <div class="section-experience__content__slide__hooper__list__item">
              <div class="section-experience__content__slide__hooper__list__item__top">
                <div class="section-experience__content__slide__hooper__list__item__top__left">
                  <img :src='experience.image' />
                </div>
                <div class="section-experience__content__slide__hooper__list__item__top__right">
                  <h3>{{ experience.age }} {{ experience.gender }}</h3>
                  <h5>{{ experience.level }}</h5>
                  <p>{{ experience.name }} {{ experience.job }}</p>
                </div>
              </div>
              <div class="section-experience__content__slide__hooper__list__item__bottom">
                <p v-html="experience.description" />
              </div>
            </div>
            <!-- <img :src="setImageUrl(img)" width="700" /> -->
          </slide>
        <hooper-pagination slot="hooper-addons" />
        <hooper-navigation slot="hooper-addons" />
        </hooper>
      </div>
    </div>
  </section>
</template>
<script>
import SectionTitle from '@/components/lps/top/general/SectionTitle.vue';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from 'hooper';

export default {
  components: {
    SectionTitle,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  data() {
    return {
      imgList: [
        'images/other/toppage/webp/course_detail_8.webp',
        'images/other/toppage/webp/course_detail_9.webp',
        'images/other/toppage/webp/course_detail_10.webp',
      ],
      experienceList: [
        {
          age: '20代後半',
          image: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/experience_3.webp`,
          gender: '女性',
          name: 'O.Aさん',
          job: 'Webデザイナー',
          level: 'HTML/CSSの知識あり',
          description: '<span>Webデザイナーからフロントエンドエンジニアにキャリアチェンジできました。</span>HTML/CSSの知識は元々あったので、JavaScriptとVue.jsの教材だけ短期間使用しました。',
        },
        {
          age: '20代後半',
          image: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/experience_2.webp`,
          gender: '男性',
          name: 'N.Kさん',
          job: 'メーカー営業職',
          level: 'プログラミング未経験',
          description: '<span>働きながら空いた時間でプログラミングを勉強出来る点</span>に惹かれて始めました。学んだことをポートフォリオに活かし無事<span>バックエンドエンジニアへの転職</span>が決まりました。',
        },
        {
          age: '20代前半',
          image: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/experience_1.webp`,
          gender: '男性',
          name: 'Y.Tさん',
          job: '大学生',
          level: '他サービスで勉強中',
          description: '必要なところだけ受講したり他のプログラミング勉強サービスと併用しながら<span>Webサービスをリリースすることが出来ました。</span>',
        },
      ],
      hooperSettingSp: {
        itemsToShow: 1.2,
        wheelControl: false,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 5000,
        centerMode: true,
      },
      hooperSettingPc: {
        itemsToShow: 1.7,
        wheelControl: false,
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 5000,
        centerMode: true,
      },
      currentSlide: 0,
    };
  },
  computed: {
    setHooperSettings() {
      const isSp = (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
      return isSp ? this.hooperSettingSp : this.hooperSettingPc;
    },
  },
  methods: {
    setImageUrl(path) {
      return `${process.env.VUE_APP_S3_MATERIAL_URL}/${path}`;
    },
    setComma(price) {
      return Math.round(price).toLocaleString();
    },
    cardToggle(obj) {
      const overview = obj;
      overview.isOpened = !overview.isOpened;
      if (!overview.isOpened) {
        this.closeToAutoScroll(overview.hardName);
      }
    },
    closeToAutoScroll(hardName) {
      const margin = 28;
      const targetPosition = document.getElementById(hardName).offsetTop;
      const headerHeight = document.getElementById('header').clientHeight;
      const position = targetPosition - headerHeight - margin;
      window.scrollTo({
        top: position,
      });
    },
  },
};
</script>
<style lang="scss">
.section-experience {
  padding: 60px 0;
  background-color: #eff5f5;
  &__content {
    margin: 0 auto;
    text-align: center;
    &__slide {
      position: relative;
      width: 100%;
      margin: 20px auto 0;
      .hooper-list {
        overflow: hidden;
      }
      .hooper-track {
        display: inline-flex;
        align-items: center;
        margin: 0;
      }
      .hooper-sr-only {
        display: none;
      }
      .hooper-indicators {
        display: none;
      }
      .hooper-prev,
      .hooper-next {
        position: absolute;
        top: 55%;
        left: 10px;
        transform: translate(0, -50%) scale(3, 5);
        opacity: 0.5;
        .icon {
          width: 18px;
        }
      }
      .hooper-next {
        left: initial;
        right: 10px;
        margin-top: 0px;
      }
      &__hooper {
        &__list {
          &__item {
            background-color: $color-white;
            margin: 0 10px;
            padding: 20px;
            min-height: 220px;
            &__top {
              display: flex;
              justify-content: start;
              align-items: center;
              &__left {
                img {
                  width: 80px;
                  height: 80px;
                }
              }
              &__right {
                text-align: left;
                margin-left: 10px;
                h3 {
                  font-size: 20px;
                  margin-bottom: 5px;
                }
                h5 {
                  font-size: 14px;
                  font-weight: 500;
                }
                p {
                  font-size: 14px;
                  color: $color-dark-gray;
                  font-weight: 400;
                }
              }
            }
            &__bottom {
              margin-top: 15px;
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              span {
                border-bottom-style: solid;
                border-color: #f7be42;
                border-width: 2px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
.section-experience__content__card__header {
  &__content {s
    p {
      font-size: 14px;
    }
  }
}
.section-experience__content__card__body {
  &__recommendation__list {
    display: block;
  }
  &__recommendation {
    width: 100%;
  }
}
}

@media screen and (max-width: 650px) {
.section-experience {
  padding: 30px 15px;
  &__content {
    &__slide {
      &__hooper {
        &__list {
          &__item {
            background-color: $color-white;
            margin: 0 10px;
            padding: 15px;
            min-height: 220px;
            &__top {
              display: flex;
              justify-content: start;
              align-items: center;
              &__left {
                img {
                  width: 60px;
                  height: 60px;
                }
              }
              &__right {
                text-align: left;
                margin-left: 10px;
                h3 {
                  font-size: 16px;
                }
                h5 {
                  font-size: 12px;
                  font-weight: 500;
                }
                p {
                  font-size: 12px;
                  color: $color-dark-gray;
                  font-weight: 400;
                }
              }
            }
            &__bottom {
              margin-top: 15px;
              font-size: 12px;
              font-weight: 400;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
}
</style>
