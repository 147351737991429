<template>
  <section :class='{"header-normal": true, "active": scrollStatus}' id='header'>
    <div class='header-normal__left'>
      <icon-logo :white="!scrollStatus" />
    </div>
    <div class='header-normal__right'>
      <ul>
        <li v-for='menuInfo in headerMenu' :key='menuInfo.id'>
          <a
            :href='"javascript:void(0);"'
            @click='selectToAutoScroll(menuInfo.link)'
            :class='{active: scrollStatus}'
          >
            {{ menuInfo.text }}
          </a>
        </li>
      </ul>
      <div class='header-normal__right__button-list'>
        <button
          :class='{"header-normal__right__button-list__login": true, active: scrollStatus}'
          @click='changeShowModal("login", true)'
        >
          ログイン
        </button>
        <button
          class='header-normal__right__button-list__signup'
          @click='changeShowModal("signup", true)'
        >
          新規登録(無料)
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import IconLogo from '@/components/atoms/icon/IconLogo.vue';

export default {
  components: {
    IconLogo,
  },
  created() {
    // header制御関連
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrollStatus: false,
      headerMenu: [
        {
          text: 'Donblerとは',
          link: 'reason',
        },
        {
          text: '料金プラン',
          link: 'plan',
        },
        {
          text: 'コース一覧',
          link: 'course',
        },
        {
          text: 'よくある質問',
          link: 'faq',
        },
      ],
    };
  },
  methods: {
    changeShowModal(target, bool) {
      this.$emit('changeShowModal', target, bool);
    },
    handleScroll() {
      const fv = document.getElementById('section-fv').clientHeight;
      if (window.scrollY > fv) {
        this.scrollStatus = true;
      } else {
        this.scrollStatus = false;
      }
    },
    selectToAutoScroll(targetSection) {
      const targetPosition = document.getElementById(targetSection).offsetTop;
      const headerHeight = document.getElementById('header').clientHeight;
      const position = targetPosition - headerHeight;
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss">
.header-normal {
  position: fixed;
  top: 0;
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 99;
  transition: 0.2s;
  &.active {
    background-color: #fff;
    transition: 0.2s;
  }
}

.header-normal__left {
  display: flex;
  align-items: center;
  img {
    width: 200px;
    .active {
      color: #202C31;
    }
  }
}

.header-normal__right {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    margin: 0;
    li {
      font-size: 14px;
      font-weight: 500;
      margin-right: 15px;
      a {
        color: #fff;
        text-decoration: none;
        &.active {
          color: #202C31;
          text-shadow: none;
        }
      }
    }
  }
}

.header-normal__right__button-list {
  display: flex;
  button {
    font-size: 14px;
    font-weight: 600;
    width: 140px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
}

.header-normal__right__button-list__login {
  background-color: #F2F5F5;
  color: #202C31;
  &.active {
    background-color: #f7be42;
  }
}

.header-normal__right__button-list__login:hover {
  background-color: #fafdfd;
  &.active {
    background-color: #fec64d;
  }
}

.header-normal__right__button-list__signup {
  background-color: #2496FF;
  color: #fff;
}

.header-normal__right__button-list__signup:hover {
  background-color: #379eff;
}

@media screen and (max-width: 1040px) {
.header-normal__right {
  ul {
    display: none;
  }
}
}
@media screen and (max-width: 650px) {
.header-normal {
  padding: 15px;
}

.header-normal__left {
  img {
    width: 140px;
  }
  p {
    margin-left: 10px;
    padding: 2px 6px;
    border: 1px solid #fff;
    font-weight: 500;
    &.active {
      border: 1px solid #202C31;
      color: #202C31;
    }
  }
}
.header-normal__right__button-list {
  display: flex;
  button {
    font-size: 10px;
    font-weight: 600;
    width: 60px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.2s;
  }
}
}
</style>
